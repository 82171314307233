import Image from "gatsby-image"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Card = props => {
  const { name, slug, summary, image, attribution } = props

  let attribution_rendered;


  if (attribution) {
    attribution_rendered = <div dangerouslySetInnerHTML={{__html: attribution.join("</br>") }} />
  }

  return (
    <div className="bg-white h-full shadow-sm rounded-md overflow-hidden group">
      <Link to={`/${slug}`}>
        <div className="group-hover:opacity-75 transition duration-150 ease-in-out">
          <Image 
	    fluid={image.childImageSharp.fluid}
	    alt={name}
	  />
    {attribution_rendered}
        </div>
        <div className="p-4 sm:p-5">
          <h1 className="sm:text-lg text-gray-900 font-semibold">{name}</h1>
          <p className="text-sm sm:text-base text-gray-700"><div dangerouslySetInnerHTML={{__html: summary}} /></p>
        </div>
      </Link>
    </div>
  )
}


Card.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  attribution: PropTypes.array,
}

export default Card


export const query = graphql`
  fragment HomophoneCard on HomophonesJson {
    id
    name
    summary
    slug
    image {
	childImageSharp {
          fluid(maxWidth: 444, maxHeight: 342, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
	}
    }
  }
`
