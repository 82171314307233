import React from "react"
import doug from "../images/doug.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Hero = () => (
  <div className="container py-2 lg:pb-8">
    <h2 className="text-2xl font-extrabold leading-tight tracking-tight text-red-700">
      An Intergalatic Guide to the Planet Called Earth  
      <br />
    </h2>
    <h3>
      <span className="text-blue-400 align-text-bottom">
	      <a href="./about">By{" "} Doug</a>, Who Doesn't Do His Job Well
      </span>
    </h3>
  </div>
)

export default Hero
